<div class="main-container">
  <div class="card imprint">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Aktuelles</a></p>
    </div>
    <h1>Impressum</h1>
    <p>
      Ute Bettenhausen & Ewald Ring<br />
      Kolpingstrasse 4<br />
      84085 Langquaid
    </p>

    <h3>Kontakt</h3>
    <p>
      Telefon: 0151 – 64 61 94 56<br />
      E-Mail: candranataraja@gmx.de
    </p>
  </div>
</div>
