<div class="main-container">
  <div class="card text-center">
    <h1>2 Töne EINklang</h1>
    <h2>
      Wir möchten Dich mitnehmen auf eine Klang – Reise.<br />
      Den Alltag vergessen – innerlich zu Ruhe kommen.
    </h2>
    <p>
      Ganzkörperliche Klangerfahrungen ermöglichen intensive Tiefenentspannung
      und Harmonisierung. Achtsames Ankommen im Moment
    </p>
    <h2>NAMASTE</h2>
  </div>
  <div class="card">
    <h1>Aktuelles</h1>
    <h2>Klangreise im Klangkeller LQ</h2>
    <p>
      {{ data[0].body }}
    </p>
    <div *ngIf="data">
      <p>
        <b>{{ data[0].date }}</b>
      </p>
      <p>{{ data[0].price }}</p>
    </div>
  </div>
  <div class="card">
    <h2>Lebensbegleitung</h2>
    <p>
      {{ data[1].body }}
    </p>
    <div *ngIf="data">
      <p>
        <b>{{ data[1].date }}</b>
      </p>
      <p>{{ data[1].price }}</p>
    </div>
  </div>
  <div class="card">
    <h2>Klangmassage – Einzelbehandlung</h2>
    <p>
      {{ data[2].body }}
    </p>
    <div *ngIf="data">
      <p>
        <b>{{ data[2].date }}</b>
      </p>
      <p>{{ data[2].price }}</p>
    </div>
  </div>
  <div class="card">
    <h2>Gong Klang Einzelbehandlung</h2>
    <p>
      {{ data[3].body }}
    </p>
    <div *ngIf="data">
      <p>
        <b>{{ data[3].date }}</b>
      </p>
      <p>{{ data[3].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 4 && data[4].title != 'test'">
    <h2>{{ data[4].title }}</h2>
    <p>
      {{ data[4].body }}
    </p>
    <div>
      <p>
        <b>{{ data[4].date }}</b>
      </p>
      <p>{{ data[4].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 5 && data[5].title != 'test'">
    <h2>{{ data[5].title }}</h2>
    <p>
      {{ data[5].body }}
    </p>
    <div>
      <p>
        <b>{{ data[5].date }}</b>
      </p>
      <p>{{ data[5].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 6 && data[6].title != 'test'">
    <h2>{{ data[6].title }}</h2>
    <p>
      {{ data[6].body }}
    </p>
    <div>
      <p>
        <b>{{ data[6].date }}</b>
      </p>
      <p>{{ data[6].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 7 && data[7].title != 'test'">
    <h2>{{ data[7].title }}</h2>
    <p>
      {{ data[7].body }}
    </p>
    <div>
      <p>
        <b>{{ data[7].date }}</b>
      </p>
      <p>{{ data[7].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 7 && data[8].title != 'test'">
    <h2>{{ data[8].title }}</h2>
    <p>
      {{ data[8].body }}
    </p>
    <div>
      <p>
        <b>{{ data[8].date }}</b>
      </p>
      <p>{{ data[8].price }}</p>
    </div>
  </div>
  <div class="card" *ngIf="data && data.length > 7 && data[9].title != 'test'">
    <h2>{{ data[9].title }}</h2>
    <p>
      {{ data[9].body }}
    </p>
    <div>
      <p>
        <b>{{ data[9].date }}</b>
      </p>
      <p>{{ data[9].price }}</p>
    </div>
  </div>
  <div class="card text-center">
    <h1>Gutscheine</h1>
    <p>
      "Entspannungs – Zeit" verschenken<br />
      Zeit zum Wohlfühlen<br />
      Zeit für mystische Klänge und Vibration<br />
      Zeit für Massage und Entspannung<br />
    </p>
    <h3>
      Das schönste Geschenk ist Zeit - Zeit für Dich, allein oder zu zweit!
    </h3>
    <h3>NAMASTE</h3>
  </div>
</div>